import React, { useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Select from 'react-select';

// Data
import { Grid, Input } from "@mui/material";
import SoftAlert from "components/SoftAlert";

import { insertDataJson, updateItem, validateForm, imgToBase64} from 'tools/helpers';
import SoftButton from 'components/SoftButton';

function ConstancyForm({setOpen, getCatalogs, formDataConstancy, event_id}) {
  const emptyData = {
    text : '',
    secundary_text : '',
    background : '',
    visitor: ''
  }
  const [formData, setFormData] = useState(formDataConstancy);
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [errorFields, setErrorsFields] = useState([]);

  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = async (event) => {
    const imgBase64 = await imgToBase64(event);
    setFormData({
      ...formData,
      background: imgBase64
    })
  }

  const requiredFields = ['background'];
  const sendForm = async() =>{
    const validationErrors = validateForm(formData, requiredFields);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Constancy`; 

    const newData = {
      ...formData,
      event_id: event_id
    }

    const send = await insertDataJson(route, newData);
  
    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el patrocinador satisfactoriamente",
        label: "success",
      });
      getCatalogs();
      setOpen(false);
      setFormData(emptyData);
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }
  
  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5"> Constancia para {formDataConstancy.visitor} </SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={12} px={1}>
                  <SoftTypography variant="caption">Texto *:</SoftTypography>
                  <textarea
                    rows="3"
                    style = {{ width : '100%', borderColor: '#cbcbcb', padding: 5 }}
                    name = "text"
                    value = {formData.text}
                    onChange={setState}
                    className= {errorFields.includes("text") ? 'error-field' : null}
                  ></textarea>
                </Grid>
                <Grid xs={12} md={12} px={1}>
                  <SoftTypography variant="caption">Texto secundario:</SoftTypography>
                  <textarea
                    rows="2"
                    style = {{ width : '100%', borderColor: '#cbcbcb', padding: 5 }}
                    name = "secundary_text"
                    value = {formData.secundary_text}
                    onChange={setState}
                    className= {errorFields.includes("secundary_text") ? 'error-field' : null}
                  ></textarea>
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Imagen de fondo*: </SoftTypography>
                  <Input
                    type="file"
                    name = "img"
                    inputProps={{
                      accept: 'image/jpeg, image/png'
                    }}
                    onChange={event => handleFile(event)}
                    className= {errorFields.includes("img") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                    <img src={formData.background} width={200} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} p={5} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick = {()=>sendForm()}
                  >
                    Guardar
                  </SoftButton>
                </Grid>
                <Grid item >
                  <SoftButton
                    md={6}
                    variant="gradient"
                    color= "dark"
                    size="medium"
                    onClick = {()=>setOpen(false)}
                  >
                    Cerrar
                  </SoftButton>
                </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

export default ConstancyForm;
