
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Grid, Icon } from "@mui/material";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

function Participants( {data, label, sendForm, deleteParticipants}) {
    const defaultColumns = [
    {
      name: 'Nombre',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Correo',
      selector: row => row.email,
      compact: true
    },
    {
      name: 'Teléfono',
      selector: row => row.phone,
      compact: true
    },
    {
      name: 'Cupón',
      selector: row => row.coupon,
      compact: true
    },
    {
    name: '',
    selector: row => <Icon onClick={()=>deleteParticipants(row)} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon>,
    compact: true
    },
  ];
    
    return(
      <SoftBox sx={8} md={12}>
        <Grid container p={1}>
        <Grid container item md={12}>
          <Grid item md={6}>
              <SoftTypography variant="h5" fontSize={18}>{label} </SoftTypography>
          </Grid>
          <Grid item container md={6} justifyContent="flex-end">
          <SoftButton
              md={12}
              variant="gradient"
              color= "primary"
              size="medium"
              onClick = {()=>sendForm()}
            >Envíar Registros
            </SoftButton>
          </Grid>
        </Grid>
        <Grid md={12}>
          <DataTableExtensions
            columns={defaultColumns}
            data={data}
            export={false}
            print={false}
            filter ={false}
            filterPlaceholder="Buscar..."
          >
            <DataTable
              columns={defaultColumns}
              data={data}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: 'Registros por página:', 
                rangeSeparatorText: 'de', 
                noRowsPerPage: false, 
                selectAllRowsItem: true, 
                selectAllRowsItemText: 'Todos',
              }}
              paginationPerPage={20}
              noDataComponent={<SoftBox py={5}>Aún no ha agregado un registro.</SoftBox>}
            />
          </DataTableExtensions>
        </Grid>
      </Grid>
    </SoftBox>
    )
  
}

export default Participants;