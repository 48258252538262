import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const AppContext = createContext();

const AppProvider = (props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <AppContext.Provider
      value={{
        setLoaded,
        loaded,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

export default AppProvider;
