
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Grid, Icon, Modal } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';

function Coupons( {data, label, setOpen, confirmDelete}) {
   const columns = [
      {
        name: 'Código',
        selector: row => row.code,
        compact: true,
      },
      {
      name: 'Total',
      selector: row => row.quantity,
      compact: true
      },
      {
        name: 'Disponible',
        selector: row => row.available,
        compact: true
      },
      {
        name: 'Vigencia',
        selector: row => row.expiration_date,
        compact: true
      },
      {
      name: '',
      selector: row => <Icon  onClick={()=>confirmDelete(row, 'Coupon')} style={{ cursor: "pointer"}} fontSize="medium" color="error">delete</Icon>,
      compact: true
      },
    ];


    
    return(
      <SoftBox sx={8} md={12}>
        <Grid container p={1}>
        <Grid container item md={12}>
          <Grid item md={6}>
              <SoftTypography variant="h5" fontSize={18}>{label} </SoftTypography>
          </Grid>
          <Grid item container md={6} justifyContent="flex-end">
            <SoftButton
              md={6}
              variant="gradient"
              color= "primary"
              size="medium"
              onClick = {()=>setOpen('coupon')}
            >
              Nuevo
            </SoftButton>
          </Grid>
            </Grid>
            <Grid>
              <DataTableExtensions
              columns={columns}
              data={data}
              export={false}
              print={false}
              filterPlaceholder="Buscar..."
            >
              <DataTable
                columns={columns}
                data={data}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: 'Registros por página:', 
                  rangeSeparatorText: 'de', 
                  noRowsPerPage: false, 
                  selectAllRowsItem: true, 
                  selectAllRowsItemText: 'Todos',
                }}
                paginationPerPage={20}
                noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
              />
            </DataTableExtensions>
        </Grid>
      </Grid>
    </SoftBox>
    )
  
}

export default Coupons;