
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";
import Select from 'react-select';

// Data
import { Divider, Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

function DataFormContact({formData, setState, selectState, errorFields, statusForm, handleFilePayments}) {

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Actualizar información de contácto del evento</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Teléfono:</SoftTypography>
                  <Input 
                    name = "phone"
                    value = {formData.phone}
                    onChange={setState}
                    className= {errorFields.includes("phone") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Email:</SoftTypography>
                  <Input 
                    type = "email"
                    name = "email"
                    value = {formData.email}
                    onChange={setState}
                    className= {errorFields.includes("email") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Whatsapp:</SoftTypography>
                  <Input 
                    type= 'number'
                    name = "whatsapp"
                    value = {formData.whatsapp}
                    onChange={setState}
                    className= {errorFields.includes("whatsapp") ? 'error-field' : null}
                  />
                  <SoftTypography variant="caption">*Escribir solo números</SoftTypography>
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Página web:</SoftTypography>
                  <Input 
                    name = "web"
                    value = {formData.web}
                    onChange={setState}
                    className= {errorFields.includes("web") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Facebook:</SoftTypography>
                  <Input 
                    name = "facebook"
                    value = {formData.facebook}
                    onChange={setState}
                    className= {errorFields.includes("facebook") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Youtube:</SoftTypography>
                  <Input 
                    name = "youtube"
                    value = {formData.youtube}
                    onChange={setState}
                    className= {errorFields.includes("youtube") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">LinkedIn:</SoftTypography>
                  <Input 
                    name = "linkedin"
                    value = {formData.linkedin}
                    onChange={setState}
                    className= {errorFields.includes("linkedin") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Twitter:</SoftTypography>
                  <Input 
                    name = "x"
                    value = {formData.x}
                    onChange={setState}
                    className= {errorFields.includes("x") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Instagram:</SoftTypography>
                  <Input 
                    name = "instagram"
                    value = {formData.instagram}
                    onChange={setState}
                    className= {errorFields.includes("instagram") ? 'error-field' : null}
                  />
                </Grid>
                <Divider />
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

DataFormContact.propTypes = {
  formData: PropTypes.object,
  setState: PropTypes.func,
  errorFields: PropTypes.string,
  statusForm: PropTypes.string,
};

export default DataFormContact;
