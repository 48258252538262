import React, { useState } from 'react';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DeleteIcon from '@mui/icons-material/Delete';

// Data
import { Divider, Grid, Input, Typography } from "@mui/material";

import SoftAlert from "components/SoftAlert";
import { Icon, Modal } from '@mui/material';

import SoftButton from 'components/SoftButton';
import { Checkbox } from '@mui/material';


function ModalAddField({
    open,
    setOpen, 
    formData, 
    formDataSelect, 
    setState, 
    setStateSelectForm, 
    statusForm, 
    listDataSelect, 
    errorFields, 
    sendForm,
    sendFormSelect,
    sendFormUpdate,
    deleteItemCatalogDetail,
    isRequiredField,
    isRequerid,
    setIsRequerid
}) {

  const listSelectType = [
    {value:"select", label: "Lista"},
    {value:"input", label: "Texto"}
  ];

  return (
    <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleForm  } md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
            <Grid md={12}>
                {
                  errorFields.length > 0 ?
                    <SoftAlert color="error"> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        Campos requeridos (*)
                      </SoftTypography>
                    </SoftAlert>
                  : null
                }
                {
                  statusForm.status ? (
                    <SoftBox extAlign="center" p={1}>
                      <SoftAlert color={statusForm.label}> 
                        <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                          {statusForm.message}
                        </SoftTypography>
                      </SoftAlert>
                    </SoftBox>
                  ): null
                }
              </Grid>
            <Grid md={12} xs={12}>
              <Grid md={12} xs={12}>
                <Typography>
                  Nuevo Campo
                </Typography>
              </Grid>
              <Grid container md={12}>
                <Grid md={6} px={2}>
                  <SoftTypography variant="caption">Nombre:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {formData.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                    padding = {10}
                  />
                </Grid>
                <Grid  md={4} px={2}>
                  <SoftTypography variant="caption">Tipo:</SoftTypography>
                  <select
                      className="select-custom"
                      name="type"
                      value={formData.type}
                      onChange={setState}
                  >
                    <option value="">Seleccione una opción</option>
                    {
                      listSelectType?.map(item=>(
                        <option value={item.value}>{item.label}</option>
                      ))
                    }
                  </select>
                </Grid>
                <Grid  container md={2} px={2} direction="column" justifyContent="center" alignItems="center" >
                  <SoftTypography variant="caption">¿Requerido?:</SoftTypography>
                  <Checkbox
                      style={{ marginTop: 5 }}
                      checked= {isRequerid}
                      color='secondary'
                      onChange = {isRequiredField}
                  />
                </Grid>
              </Grid>
              <Divider />
              {
                formData.type === 'select' && formData.id !== '' && formData.id ?
                  <Grid md={12} container bgcolor="#f5f5f5" borderRadius={3} >
                    <Grid  md={6} p={3}>
                      <Input 
                          size='small'
                          name = "value"
                          value = {formDataSelect.value}
                          onChange={setStateSelectForm}
                          padding = {10}
                        />
                      <SoftButton
                        style= {{ marginTop: 3 }}
                        md={6}
                        variant="gradient"
                        color= "info"
                        size="small"
                        onClick = {()=>sendFormSelect()}
                        >
                            Agregar a lista
                      </SoftButton>
                    </Grid>
                    <Grid container md={6} p={3}>
                      <SoftTypography variant="caption"><b>Listado:</b></SoftTypography>
                      <Grid md={8} mt={2}>
                        {
                          listDataSelect.map(item=>(
                            <Grid container mt={1} alignItems="center">
                              <SoftTypography variant="caption">- {item.value}</SoftTypography>
                              <DeleteIcon 
                                fontSize="small" 
                                color="error" 
                                mt={3} 
                                style={{ cursor: 'pointer' }}
                                onClick = {()=>deleteItemCatalogDetail(item)}
                                 />
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                : null
              }
              <Divider />
              <Grid container xs={12} md={6} px={1} mt={5} spacing={2}>
                {
                  formData.id && formData.id !== '' ?
                    <SoftButton
                      md={6}
                      variant="gradient"
                      color= "warning"
                      size="medium"
                      onClick = {()=>sendFormUpdate()}
                      >
                          Actualizar nombre del campo
                    </SoftButton>
                  :
                    <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendForm()}
                      >
                          Agregar campo
                    </SoftButton>
                }
                <SoftButton
                  style={{ marginLeft: 5 }}
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpen(false)}
                  >
                      Cerrar
                </SoftButton>
            </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
  );
}

const styleForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  maxWidth: '900px',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default ModalAddField;
