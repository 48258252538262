import React from 'react';

import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import { Modal } from '@mui/material';
import SoftButton from './SoftButton';

function ModalConfirm({open, setOpen, action, message}) {
  return (
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleDialog} md={12}>
          <Grid container md={12} justifyContent='center'>
            <Grid item mb={1}>
                <SoftTypography variant="caption">{message}</SoftTypography>
            </Grid>
            <Grid item md={12} spacing={2} textAlign='center'>
                <SoftButton
                    style={{ marginLeft: 5 }}
                    md={6}
                    variant="gradient"
                    color= "info"
                    size="medium"
                    onClick = {()=>action()}
                    >
                        Aceptar
                </SoftButton>
                <SoftButton
                    style={{ marginLeft: 5 }}
                    md={6}
                    variant="gradient"
                    color= "dark"
                    size="medium"
                    onClick = {()=>setOpen(false)}
                    >
                        Cerrar
                </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
  );
}

const styleDialog = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '150px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default ModalConfirm;
