
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import PropTypes from "prop-types";
import Select from 'react-select';

// Data
import { Divider, Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

function DataForm({formData, setState, selectState, errorFields, statusForm, handleFilePayments}) {

  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5">Actualizar información del evento</SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nombre evento *:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {formData.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Slug*: </SoftTypography>
                  <Input 
                    name = "slug"
                    value = {formData.slug}
                    onChange={setState}
                    disabled
                    className= {errorFields.includes("slug") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Descripción del evento:</SoftTypography>
                  <textarea
                    rows="5"
                    style = {{ width : '100%', borderColor: '#cbcbcb', padding: 5 }}
                    name = "description"
                    value = {formData.description}
                    onChange={setState}
                    className= {errorFields.includes("description") ? 'error-field' : null}
                  ></textarea>
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Sede:</SoftTypography>
                  <Input 
                    name = "sede"
                    value = {formData.sede}
                    onChange={setState}
                    className= {errorFields.includes("sede") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Costo general:</SoftTypography>
                  <Input
                    type="number"
                    name = "general_cost"
                    value = {formData.general_cost}
                    onChange={setState}
                    className= {errorFields.includes("general_cost") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Dirección:</SoftTypography>
                  <Input 
                    name = "address"
                    value = {formData.address}
                    onChange={setState}
                    className= {errorFields.includes("address") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Fecha inicio:</SoftTypography>
                  <Input 
                    type="date"
                    name = "date_init"
                    value = {formData.date_init}
                    onChange={setState}
                    className= {errorFields.includes("date_init") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Hora inicio:</SoftTypography>
                  <Input 
                    type="time"
                    name = "time_init"
                    value = {formData.time_init}
                    onChange={setState}
                    className= {errorFields.includes("time_init") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Empresa/Compañia:</SoftTypography>
                  <Input 
                    name = "company"
                    value = {formData.company}
                    onChange={setState}
                    className= {errorFields.includes("company") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Responsable:</SoftTypography>
                  <Input 
                    name = "responsible"
                    value = {formData.responsible}
                    onChange={setState}
                    className= {errorFields.includes("responsible") ? 'error-field' : null}
                  />
                </Grid>
                {/* <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Tipo evento:</SoftTypography>
                  <Select 
                    className="z-101"
                    options={dataCities} 
                    onChange={selectState}
                    value={dataCities.filter(item => item.value === formData.city?.id)[0]}
                  />
                </Grid> */}
              </Grid>
                <Grid container md={12} mt={1} spacing={2} >
                  <Grid md={6} xs={12} mt={1} spacing={2} >
                    <SoftTypography variant="caption">Imagen Transferencia: </SoftTypography>
                    <Input
                      type="file"
                      name = "img_data_transfer"
                      inputProps={{
                        accept: 'image/jpeg, image/png'
                      }}
                      onChange={event => handleFilePayments(event)}
                      className= {errorFields.includes("img_data_transfer") ? 'error-field' : null}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} mt={1} spacing={2} justifyContent="center" textAlign="center" >
                    { formData.img_data_transfer ? <img width="200" src={formData.img_data_transfer} alt="Imagen Transferencia"/> : null }
                  </Grid>
                  <Grid md={6} xs={12} mt={1} spacing={2} >
                    <SoftTypography variant="caption">Imagen Depósito: </SoftTypography>
                    <Input
                      type="file"
                      name = "img_data_deposit"
                      inputProps={{
                        accept: 'image/jpeg, image/png'
                      }}
                      onChange={event => handleFilePayments(event)}
                      className= {errorFields.includes("img_data_deposit") ? 'error-field' : null}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} mt={1} spacing={2} justifyContent="center" textAlign="center">
                  { formData.img_data_deposit ?  <img width="200" src={formData.img_data_deposit} alt="Imagen Transferencia"/> : null }
                  </Grid>
                  <Grid md={6} xs={12} mt={1} spacing={2} >
                    <SoftTypography variant="caption">Imagen Pagar aquí: </SoftTypography>
                    <Input
                      type="file"
                      name = "img_button_payments"
                      inputProps={{
                        accept: 'image/jpeg, image/png'
                      }}
                      onChange={event => handleFilePayments(event)}
                      className= {errorFields.includes("img_button_payments") ? 'error-field' : null}
                    />
                    <SoftTypography variant="caption">Url Pago Tarjeta:</SoftTypography>
                    <Input 
                      name = "link_payments"
                      value = {formData.link_payments}
                      onChange={setState}
                      className= {errorFields.includes("link_payments") ? 'error-field' : null}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} mt={1} spacing={2} justifyContent="center" textAlign="center">
                    { formData.img_button_payments ?  <img width="200" height="auto" src={formData.img_button_payments} alt="Imagen Transferencia"/> : null }
                  </Grid>
                  <Grid xs={12} md={6} px={1} mt={5}>
                    <SoftTypography variant="caption">Email para notificaciones:</SoftTypography>
                    <Input 
                    type = "email"
                    name = "email_notification"
                    value = {formData.email_notification}
                    onChange={setState}
                    className= {errorFields.includes("email_notification") ? 'error-field' : null}
                  />
                  </Grid>
                </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

DataForm.propTypes = {
  formData: PropTypes.object,
  setState: PropTypes.func,
  errorFields: PropTypes.string,
  statusForm: PropTypes.string,
};

export default DataForm;
