import React from 'react';
import SoftTypography from "components/SoftTypography";

// Data
import { Grid, Input } from "@mui/material";

function InputForm({item, setState, formData, errorFields}) {
  return (
    <Grid container xs={12} md={6} px={1}>
      <Grid xs={12} md={12}>
        <SoftTypography key={item.name} variant="caption">{item.name}{item.requerid || parseInt(item.status) === 1 ? '*' : ''}:</SoftTypography>
        <Input 
          key={item.key}
          name = {item.key}
          value = {formData[item.key]}
          onChange={setState}
          className= {errorFields.includes(item.key) ? 'error-field' : null}
          padding = {10}
        />
        {
          item.helperText != '' ?
            <SoftTypography fontWeight="bold" key={item.helperText} variant="caption">{item.helperText}</SoftTypography>
          : null
        }
      </Grid>
  </Grid>
  );
}

export default InputForm;
