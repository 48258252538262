
import { Icon } from "@mui/material";
import Logout from "auth/Logout";
import Events from "layouts/events";

const routes = [
  { type: "title", title: "ADMINISTRADOR", key: "account-pages" },
  {
    type: "collapse",
    name: "Eventos",
    key: "events",
    route: "/events",
    icon: <Icon color="white">assignment_ind</Icon>,
    component: <Events />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Cerrar Sesión",
    key: "login",
    route: "logout",
    icon: <Icon color="white">logout</Icon>,
    component: <Logout />,
    noCollapse: true,
  },
];

export default routes;
