
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { Grid, Icon, Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import { getData } from "tools/helpers";

import SoftAlert from "components/SoftAlert";
import { insertDataJson, updateItem, deleteItem } from "tools/helpers";
import { Mail } from "@mui/icons-material";
import { KEY_USER } from "constans";


function Constancy() {
  const { event_id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [type, setType] = useState(null);
  const [status, setStatus] = useState({status: null, message: ""});
  const [messageModal, setMessageModal] = useState("");


  const user = JSON.parse(localStorage.getItem(KEY_USER));

  const columns = [
    {
        name: 'Tipo',
        selector: row => row.type_visitor,
    },
    {
      name: 'Nombre',
      selector: row => row.name,
      compact: true,
      grow:2
   },
    {
    name: 'Email',
    selector: row => row.email,
    compact: true
    },
    {
        name: 'Teléfono',
        selector: row => row.phone,
        compact: true
    },
    {
    name: 'Envíar',
    selector: row => <Mail  onClick={()=>showModal('one', row)} style={{ cursor: "pointer"}} fontSize="medium" color={`${row.constancy === 'true' ? 'success' : 'disabled'}`}>mail</Mail>,
    compact: true
    },
  ];

  const getParticipants = async () => {
    const route = `Register/${event_id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data.data);
    }
  };

  const showModal = (type, row = null) =>{

    setType(type);
    setOpen(true);

    if(type === 'one'){
      setMessageModal(`¿Está seguro que desea enviar la constancia a ${row.name}?`);
      setFormData(row);
      return;
    }
  
    setMessageModal(`¿Está seguro que desea enviar las constancias a todos los participantes restantes?`);
  }

  const sendConstancy = async() => {
    setOpen(false);
    setStatus({
      status: true,
      message: 'Enviando espere un momento...',
      label: "info",
    })

    const route = `Send-Constancy`;
    const newData = {
      type,
      event_id,
      register_id: type === 'one' ? formData.id : null
    };

    const send = await insertDataJson(route, newData);

    if (send.code === 200) {
      setStatus({
        status: true,
        message: type === 'one' ? 'La constancia se ha enviado correctamente' : 'Todas las constancias restantes se han enviado correctamente',
        label: "success",
      })
      getParticipants();
      return;
    }

    if (send.code === 404) {
      setStatus({
        status: true,
        message: send.message,
        label: "warning",
      })
      return;
    }

    setStatus({
      status: true,
      message: 'Ocurrió algo y no se pudo realizar el envío',
      label: "error",
    })
  }

  useEffect(()=>{
    getParticipants();
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8} md={12}>
            <Grid container p={4}>
            <Grid container item md={12}>
                <Grid item md={6}>
                  <SoftTypography variant="h5">Eventos </SoftTypography>
                </Grid>
                <Grid item container md={6} justifyContent="flex-end">
                  {
                    user.type === 'admin' || user.admin === 'true' ?
                      <SoftButton
                        md={6}
                        variant="gradient"
                        color= "primary"
                        size="medium"
                        onClick = {()=>showModal('all', {})}
                      >
                        ENVÍAR A TODOS
                      </SoftButton>
                    : null
                  }
                </Grid>
              </Grid>
              <Grid container item md={12}>
                <Grid item md={6}>
                  <SoftTypography variant="h5">Constancias </SoftTypography>
                </Grid>
              </Grid>
              <Grid xs={12}>
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
            </Grid>
              <DataTableExtensions
                columns={columns}
                data={data}
                export={false}
                print={false}
                filterPlaceholder="Buscar..."
              >
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationComponentOptions={{
                    rowsPerPageText: 'Registros por página:', 
                    rangeSeparatorText: 'de', 
                    noRowsPerPage: false, 
                    selectAllRowsItem: true, 
                    selectAllRowsItemText: 'Todos',
                  }}
                  paginationPerPage={50}
                  noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                />
              </DataTableExtensions>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleCancel}>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
                {messageModal}
            </SoftTypography>
          <Grid container xs={12} mt={1} spacing={2} justifyContent='center'>
            <Grid item >
                <SoftButton
                    md={6}
                    variant="gradient"
                    color= "success"
                    size="medium"
                    onClick = {()=>sendConstancy()}
                >
                    Sí, enviar constancia
              </SoftButton>
            </Grid>
            <Grid item >
              <SoftButton
                md={6}
                variant="gradient"
                color= "dark"
                size="medium"
                onClick = {()=>setOpen(false)}
              >
                Cerrar
              </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

const styleCancel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default Constancy;
