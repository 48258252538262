import React from 'react';
import SoftTypography from "components/SoftTypography";

// Data
import { Grid, Input } from "@mui/material";

function SelectForm({item, setState, formData, errorFields}) {
  return (
    <Grid container xs={12} md={6} px={1}>
      <Grid md={12} >
        <SoftTypography key={item.name} variant="caption">{item.name}{item.requerid || parseInt(item.status) === 1 ? '*' : ''}:</SoftTypography>
        <select
          className={`select-custom ${errorFields.includes(item.key) ? 'error-field' : null} `}
          key={item.key}
          name={item.key}
          onChange={setState}
        >
          <option value="">Seleccione una opción</option>
          {
            item.catalogs_details.map(item=>(
              <option value={item.value} >{item.value}</option>
            ))
          }
        </select>
      </Grid>
    </Grid>
  );
}

export default SelectForm;
