import React from 'react';
import { Navigate } from 'react-router-dom';
import SignIn from "layouts/authentication/sign-in";
import { KEY_USER } from 'constans';
 
function RouteGuard2(){
   // check user has JWT token
   let routeCustom = '/events';
   const user = JSON.parse(localStorage.getItem(KEY_USER));
   if(localStorage.getItem(KEY_USER)){
      if(user.type === 'admin'){
      	routeCustom = '/events';
      }
      if(user.type === 'user'){
      	routeCustom = '/events';
      }
      return <Navigate to={routeCustom} />
   }
   return <SignIn />
};

export default RouteGuard2;