import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
import { Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

import { insertDataJson, getData, validateForm} from 'tools/helpers';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import HeaderEvent from 'layouts/event/components/HeaderEvent';
import SoftButton from 'components/SoftButton';


function FinishRegistration() {
  const { slug, } = useParams();
  const [eventData, setEventData] = useState({});
  const [statusForm, setStatusForm] = useState({status: null, message: ""});

  const getEvent = async () => {
    setStatusForm({status: true, message: "Consultando..."});
    const route = `Event-Slug/${slug}`;
    const send = await getData(route);
    if (send.code === 200) {
      setEventData(send.data);
    }
    setStatusForm({
      status: true,
      message: "Se ha realizado el pre-registro satisfactoriamente",
      label: "success",
    });
  };

  useEffect(()=>{
    getEvent();
  },[])

  return (
    <PageLayout>
      <SoftBox p={5}>
        <HeaderEvent
        formData = {eventData}
        />
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8} justifyContent="center" alignContent="center" style={{display: 'flex'}}>
            <Grid p={4} container  style={{ maxWidth: 1100 }}>
              <Grid md={12}>
                <SoftTypography variant="h2" fontWeight="bold" >Finaliza tu Registro</SoftTypography>
              </Grid>
              <Grid md={12}>
                {
                  statusForm.status ? (
                    <SoftBox extAlign="center" p={1}>
                      <SoftAlert color={statusForm.label}> 
                        <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                          {statusForm.message}
                        </SoftTypography>
                      </SoftAlert>
                    </SoftBox>
                  ): null
                }
              </Grid>
              <Grid pt={3} xs={12} container justifyContent="center">
                <Grid item md={6} p={3}>
                  <img width="100%" src={eventData.img_data_transfer} />
                </Grid>
                <Grid item md={6} p={3}>
                  <img width="100%" src={eventData.img_data_deposit} />
                </Grid>
                <Grid item md={12} p={3} textAlign="center">
                  <a href={eventData.link_payments} ><img width="350" src={eventData.img_button_payments} /></a>
                </Grid>
              </Grid>
            {/* <Grid xs={12} md={6} px={1} mt={5}>
              <SoftButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick={() => {
                      window.location.href = `${eventData.web}`;
                  }}
                >
                    Ir al Inicio
              </SoftButton>
            </Grid> */}
          </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
    </PageLayout>
  );
}

export default FinishRegistration;
