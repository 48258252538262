
import { Icon } from "@mui/material";
import Logout from "auth/Logout";

const routes = [
  {
    type: "collapse",
    name: "Cerrar Sesión",
    key: "login",
    route: "logout",
    icon: <Icon color="white">logout</Icon>,
    component: <Logout />,
    noCollapse: true,
  },
];

export default routes;
