import { KEY_USER } from 'constans';
import React from 'react';
import { Outlet , Navigate } from 'react-router-dom';
 
function RouteGuard(){
   // check user has JWT token
   if(localStorage.getItem(KEY_USER)){
      return <Outlet />
   }

   return <Navigate to="/login" />
};

export default RouteGuard;