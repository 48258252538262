import React, { useState } from 'react';

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import Select from 'react-select';

// Data
import { Grid, Input } from "@mui/material";
import SoftAlert from "components/SoftAlert";

import { insertDataJson, updateItem, validateForm, imgToBase64} from 'tools/helpers';
import SoftButton from 'components/SoftButton';

function AreaForm({setOpen, getCatalogs = {getCatalogs}, isEdit = false,  event_id}) {
  const emptyData = {
    name : '',
    access: 'General'
  }
  const [formData, setFormData] = useState(emptyData);
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [errorFields, setErrorsFields] = useState([]);

  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function selectState(e, item) {
    setFormData({
      ...formData,
      [item.name]: e.value,
    })
  }

  const requiredFields = ['name'];
  const sendForm = async() =>{
    const validationErrors = validateForm(formData, requiredFields);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Area`;
    let send = null;

    const newData = {
      ...formData,
      event_id: event_id
    }

    if(isEdit){
      send = await updateItem(`${route}/${formData.id}`, newData);
    }else{
      send = await insertDataJson(route, newData);
    }

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el área satisfactoriamente",
        label: "success",
      });
      setOpen(false);
      setFormData(emptyData);
      getCatalogs();
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }
  
  return (
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8}>
            <Grid p={4}>
              <SoftTypography variant="h5"> Áreas </SoftTypography>
              {
                errorFields.length > 0 ?
                  <SoftAlert color="error"> 
                    <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                      Campos requeridos (*)
                    </SoftTypography>
                  </SoftAlert>
                : null
              }
              {
                statusForm.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={statusForm.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {statusForm.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
              <Grid py={3} xs={12} container >
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Nombre *:</SoftTypography>
                  <Input 
                    name = "name"
                    value = {formData.name}
                    onChange={setState}
                    className= {errorFields.includes("name") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Ubicación:</SoftTypography>
                  <Input 
                    name = "location"
                    value = {formData.location}
                    onChange={setState}
                    className= {errorFields.includes("location") ? 'error-field' : null}
                  />
                </Grid>
                <Grid xs={12} md={6} px={1}>
                  <SoftTypography variant="caption">Tipo*: </SoftTypography>
                  <Select
                      className="z-101"
                      name="access"
                      options={[
                        {value:"General", label: "General"},
                        {value:"Pago", label: "Pago"}
                      ]} 
                      onChange={(e, item)=>selectState(e, item)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} p={5} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick = {()=>sendForm()}
                  >
                    Guardar
                  </SoftButton>
                </Grid>
                <Grid item >
                  <SoftButton
                    md={6}
                    variant="gradient"
                    color= "dark"
                    size="medium"
                    onClick = {()=>setOpen(false)}
                  >
                    Cerrar
                  </SoftButton>
                </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
  );
}

export default AreaForm;
