/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Soft UI Dashboard React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Soft UI Dashboard React themes
import theme from "assets/theme";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Soft UI Dashboard React routes
import routes from "routes";
import routesUser from 'routes_user';

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logo-ct.png";
import RouteGuard from "auth/RouteGuard";
import RouteGuard2 from "auth/RouteGuard2";
import SignIn from "layouts/authentication/sign-in";


import Event from "layouts/event"
import Register from "layouts/register";

import './style.css';
import { Badge } from "@mui/material";

import { AppContext } from "./contextApp";
import { KEY_USER } from "constans";
import PublicRegister from "layouts/publicRegister";
import FinishRegistration from "layouts/finishRegistration";
import Constancy from "layouts/constancy";

export default function App() {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(KEY_USER)));
  const { numProductCart, setNumProductCart } = useContext(AppContext);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  // Setting page scroll to 0 when changing the route
  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  // }, [pathname]);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem(KEY_USER)));
}, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
  });

  const getCustomRoutes = () =>{
    let customRoutes = routesUser;
    if(userData){
      if(userData.type === 'admin'){
        customRoutes = routes;
      }
      if(userData.type === 'user'){
        customRoutes = routes;
      }
    }
    return customRoutes;
  }

    const content = useMemo(() => <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Come to Play"
            routes={getCustomRoutes()}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        <Route exact path='/' element={<RouteGuard/>}> 
          <Route exact path='/' element={<RouteGuard2/>} />
          {getRoutes(getCustomRoutes())}
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/event/:id" element={<Event />} />
          <Route path="/register/:slug/edit/:register_form_id" element={<Register />} />
          <Route path="/constancy/:event_id" element={<Constancy />} />
        </Route>
        <Route exact path='/login' element={<SignIn />} />
        <Route path="/register/:slug/:register_form_id" element={<PublicRegister />} />
        <Route path="/finish/:slug" element={<FinishRegistration />} />
      </Routes>
    </ThemeProvider>,[layout, pathname]);

  return content;
}
