import React, {useContext} from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

import RobotoBold from '../../assets/fonts/RobotoCondensed-Bold.ttf'
import RobotoRegular from '../../assets/fonts/RobotoCondensed-Regular.ttf'

Font.register({
  family: 'Roboto Condensed Bold',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Roboto Condensed Regular',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 'bold',
    },
  ],
});

Font.registerHyphenationCallback(word => (
  [word]
));

// Estilos
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 15,
  },
  containerPage: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    paddingBottom: 15
  },
  container: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  image: {
    width: '2.4cm',
    height: '2.4cm',
    marginRight: '1cm',
  },
  textContainer: {
    justifyContent: 'center',
    flex: 2,
    paddingLeft: 20
  },
  name: {
    fontFamily: 'Roboto Condensed Bold',
    fontWeight: 'bold',
    fontSize: '13pt',
    lineHeight: 1,
    hyphenationCallback: (word) => null,
  },
  bigName: {
    fontFamily: 'Roboto Condensed Bold',
    fontWeight: 'bold',
    fontSize: '24pt',
    lineHeight: 1,
    hyphenationCallback: (word) => null,
  },
  position: {
    fontFamily: 'Roboto Condensed Regular',
    fontSize: '10pt',
    marginTop: '8pt',
  },
});

const customPageSizeCm = {
  width: 8 * 28.35, 
  height: 5 * 28.35, 
};

const getProfile = (data) =>{
  const profile = data.register_data_catalogs.filter(item => item.name.toUpperCase() === 'PERFIL')[0];
  if ((profile.value.toUpperCase()).startsWith('ESTUDIANTE')) {
      return 'ESTUDIANTE';
  }

  if ((profile.value.toUpperCase()).startsWith('PROFESIONISTA')) {
    return 'PROFESIONISTA';
  }

  return profile.value;
}

const gafetePDFGenerator = ({ data, qr }) => (
  
  <Document>
    <Page size={customPageSizeCm} style={styles.page}>
      <View style={styles.containerPage}>
        <View style={styles.container}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Image style={styles.image} src={qr} />
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.name}>{data.name?.toUpperCase()}</Text>
            <Text style={styles.position}>{data.org?.toUpperCase()}</Text>
            {/* <Text style={styles.position}>{data.position?.toUpperCase()}</Text> */}
          </View>
        </View>
        <View style={{ flex: 1, paddingBottom: 10 }}>
          {
            data.register_data_catalogs.length > 0 ?
              getProfile(data).toUpperCase().length <= 17 ?
                <Text style={styles.bigName}>{getProfile(data).toUpperCase()}</Text>
              : 
                <Text style={styles.name}>{getProfile(data).toUpperCase()}</Text>
            : null
          }
        </View>
      </View>
    </Page>
  </Document>
);

export default gafetePDFGenerator;
