
import React, { useState } from 'react';
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Grid, Icon, Modal } from "@mui/material";

import { useParams } from "react-router-dom";

import { CheckBox, EditNote, RemoveRedEye } from "@mui/icons-material";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import { DOMAIN_API } from "constans";
import { KEY_USER } from 'constans';

function Constancy( {label, setOpen, data}) {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(KEY_USER)));
  const { id } = useParams();
  const previewConstancy = (row)=>{
    const url = `${DOMAIN_API}Constancy-Preview/${id}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  }

   const columns = [
      {
        name: 'Tipo',
        selector: row => row.visitor,
        sortable: true,
      },
      {
        name: 'Previo',
        selector: row => <RemoveRedEye onClick={()=>previewConstancy(row)} style={{ cursor: `${parseInt(row.status) === 1 ? 'pointer' : ''}`}} fontSize="medium" color={ parseInt(row.status) === 1 ? 'success' : 'disabled'}>check</RemoveRedEye>,
        compact: true
        },
      {
      name: 'Editar',
      selector: row => userData.type === 'admin' ? <EditNote onClick={()=>setOpen(row, 'constancy')} style={{ cursor: "pointer"}} fontSize="medium" color="error">edit</EditNote> : null,
      compact: true
      },
    ];
    
    return(
      <SoftBox sx={8} md={12}>
        <Grid container p={1}>
        <Grid container item md={12}>
          <Grid item md={12}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">{label} </SoftTypography>
          </Grid>
            </Grid>
            <Grid>
              <DataTableExtensions
              columns={columns}
              data={data}
              export={false}
              print={false}
              filterPlaceholder="Buscar..."
              filter = {false}
            >
              <DataTable
                columns={columns}
                data={data}
                pagination = {false}
                paginationComponentOptions={{
                  rowsPerPageText: 'Registros por página:', 
                  rangeSeparatorText: 'de', 
                  noRowsPerPage: false, 
                  selectAllRowsItem: true, 
                  selectAllRowsItemText: 'Todos',
                }}
                paginationPerPage={20}
                noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
              />
            </DataTableExtensions>
        </Grid>
      </Grid>
    </SoftBox>
    )
  
}

export default Constancy;