import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsApp from "@mui/icons-material/WhatsApp";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "layouts/event/components/Header";

// Data
import profilesListData from "layouts/event/data/profilesListData";

import { Modal, Input, Divider } from '@mui/material';
import { getData, deleteItem } from 'tools/helpers';
import SoftButton from 'components/SoftButton';
import { pdf } from '@react-pdf/renderer';

import DataForm from './dataForm';
import DataFormContact from './dataFormContact';
import { validateForm, updateItem, insertDataJson, imgToBase64 } from 'tools/helpers';

import SponsorForm from './catalogs/sponsor/form';
import Sponsors from './catalogs/sponsor';

import Participants from './catalogs/participants';
import Areas from './catalogs/area';
import ModalConfirm from 'components/ModalConfirm';
import AreaForm from './catalogs/area/form';
import Coupons from './catalogs/coupons';
import CouponForm from './catalogs/coupons/form';
import Costs from './catalogs/costs';
import CostsForm from './catalogs/costs/form';
import DataFormParticipant from './dataFormParticipant';
import GafetePDFGenerator from 'tools/pdf/gafetePDFGenerator';
import Constancy from './catalogs/constancy';
import ConstancyForm from './catalogs/constancy/form';
import { KEY_USER } from 'constans';

function Overview() {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(KEY_USER)));
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [open, setOpen] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openFormContact, setOpenFormContact] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const [openDataParticipant, setOpenDataParticipant] = useState(false);
  const [formDataConstancy, setFormDataConstancy] = useState(false);
  const [dataParticipant, setDataParticipant] = useState({});
  const [dataModalCoverLogo, setDataModalCoverLogo] = useState({
    label: '',
    field: ''
  });

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmGafete, setOpenConfirmGafete] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState('');
  const [dataDelete, setDataDelete] = useState({});
  const [urlDelete, setUrlDataDelete] = useState({});
  const [dataRegister, setDataRegister] = useState({});

  const [participants, setParticipants] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [areas, setAreas] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [costs, setCosts] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [constancies, setConstancies] = useState([]);
  const [formSelected, setFormSelected] = useState('');

  const emptyData = {
    slug : '',
    name : '',
  }
  const [formData, setFormData] = useState(emptyData);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [errorFields, setErrorsFields] = useState([]);
  const [uploading, setUploading] = useState(false);


  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const setStateParticipant = (e) => {
    setDataParticipant({
      ...dataParticipant,
      [e.target.name]: e.target.value,
    });
  };

  function selectState(e, item) {
    setFormData({
      ...formData,
      [item.name]: e.value,
    })
  }

  const getEvent = async () => {
    const route = `Event/${id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setFormData(send.data);
      setProfilePhoto(send.principal_photo);
    }
  };

  const getCatalogs = async () => {
    const route = `Event-Catalogs/${id}`;
    const send = await getData(route);
    if (send.code === 200) {
        setSponsors(send.data.sponsors);
        setAreas(send.data.areas);
        setCoupons(send.data.coupons);
        setCosts(send.data.costs);
        setConstancies(send.data.constancies);
        
        const newList = [];
        send.data?.perfiles?.catalogs_details.forEach(item => {
          newList.push({
            value: item.id,
            label: item.value
          })
        })
        setPerfiles(newList);
    }
  };

  const getParticipants = async () => {
    const route = `Register/${id}`;
    const send = await getData(route);
    if (send.code === 200) {
        setParticipants(send.data);
    }
  };

  const { id } = useParams();
  const requiredFieldsForm = ['name', 'web'];
  const sendForm = async() =>{
    const validationErrors = validateForm(formData, requiredFieldsForm);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Event`;
    const send = await updateItem(`${route}/${formData.id}`, formData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el evento satisfactoriamente",
        label: "success",
      });
      setOpenForm(false);
      getEvent();
      return;
    }

    if (send.status === "error") {
      requiredFieldsForm.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }
  }

  const requiredFieldsFormParticipant = ['name', 'email'];
  const sendFormParticipant = async() =>{
    const validationErrors = validateForm(dataParticipant, requiredFieldsFormParticipant);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Register`;
    const send = await updateItem(`${route}/${dataParticipant.id}`, dataParticipant);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha actualizado el participante satisfactoriamente",
        label: "success",
      });
      setOpenDataParticipant(false);
      getParticipants();
      return;
    }

    if (send.status === "error") {
      requiredFieldsForm.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }
  }

  const sendCoverForm = async (imgBase64) =>{
    setUploading(true);
    const route = `Event`;
    const send = await updateItem(`${route}/${formData.id}`, {[dataModalCoverLogo.field]: imgBase64});

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el evento satisfactoriamente",
        label: "success",
      });
      setOpenCover(false);
      getEvent();
    }
    setUploading(false);
  }

  const handleFile = async (event) => {
    const imgBase64 = await imgToBase64(event);
    sendCoverForm(imgBase64);
  }

  const handleFilePayments = async (event) => {
    const imgBase64 = await imgToBase64(event);
    setFormData({
      ...formData,
      [event.target.name]: imgBase64
    });
  }

  const modalCoverLogo = async (label, field) =>{

    if(userData.type !== 'admin')
      {
        return;
      }
    setDataModalCoverLogo({
      label,
      field 
    })
    setOpenCover(true);
  }

  const sendMailNotification = async (dataMail, type) => {
    const route = `Mail-Preregister`;
    const newData = {
      ...dataMail,
      type: type
    }
    const send = await insertDataJson(route, newData);
  }

  const deleteItemSelected = async () =>{
    const send = await deleteItem(`${urlDelete}/${dataDelete.id}`);
    if (send.code === 200) {
      setStatusForm({
        status: true,
        message: "Se ha eliminado el registro",
        label: "success",
      });
      getCatalogs();
      getParticipants();
      setOpenConfirmDelete(false);
      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const confirmDelete = (item, url) =>{
    setOpenConfirmDelete(true);
    setMessageConfirm(`¿Está seguro que desea eliminar [${item.name ?? item.code}]?`)
    setDataDelete(item);
    setUrlDataDelete(url);
  }

  const approveItemSelected = async () =>{
    const newData = {
      status: parseInt(dataRegister.status) === 1 ? 0 : 1
    }
    const send = await updateItem(`Register/${dataRegister.id}`, newData) ;
    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha aprobado el registro",
        label: "success",
      });
      getParticipants();
      setOpenConfirm(false);

      if(parseInt(send.data.status) === 1){
        setTimeout(()=>{
          sendMailNotification(
            {
              participants: [send.data],
              slug: formData.slug
            }, 'especial');
        }, 3000);
      }

      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const approveGafeteItemSelected = async () =>{
    const newData = {
      gafete: parseInt(dataRegister.gafete) === 'true' ? 'false' : 'true'
    }
    const send = await updateItem(`Register/${dataRegister.id}`, newData) ;
    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha aprobado el gafete",
        label: "success",
      });
      getParticipants();
      setOpenConfirmGafete(false);

      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const confirmRegister = (item) =>{
    setOpenConfirm(true); 
    setMessageConfirm(`¿Está seguro que desea ${parseInt(item.status) === 1 ? 'desaprobar' : 'aprobar'} el pre-registro [${item.name}]?`)
    setDataRegister(item);
  }

  const confirmGafete = (item) =>{
    setOpenConfirmGafete(true); 
    setMessageConfirm(`¿Está seguro que desea ${parseInt(item.status) === 'true' ? 'desaprobar' : 'aprobar'} el gafete [${item.name}]?`)
    setDataRegister(item);
  }

  const showFormOtherCatalogs = (form)=>{
    setFormSelected(form);
    setOpen(true)
  }

  const showFormOtherConstancy = (row, form)=>{
    setFormDataConstancy(row);
    setFormSelected(form);
    setOpen(true)
  }

  useEffect(()=>{
    getEvent();
    getCatalogs();
    getParticipants();
  },[])


  const downloadPDFGafete = async(row) => {
    const qr = row.qr;
    const blob = await pdf(
        <GafetePDFGenerator data={row} qr={qr} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Etiqueta_Gafete.pdf`;
    window.open(link, '_blank');
  }

  return (
    <DashboardLayout>
      <Header 
      profilePhoto = {profilePhoto}
      formData = {formData}
      modalCoverLogo = {modalCoverLogo}
      />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              title="INFORMACIÓN DEL EVENTO"
              description= {formData?.description}
              info={{
                Nombre: formData.name,
                Sede: formData.sede,
                Dirección: formData.address,
                Fecha: formData.date_initr,
                Hora: formData.time_init,
                Empresa: formData.company,
                Responsable: formData.responsible,
                Costo_General: formData?.general_cost,
              }}
              action={{ route: "", tooltip: "Editar evento" }}
              setOpenForm = {setOpenForm}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              title="DATOS DE CONTACTO DEL EVENTO"
              info={{
                Teléfono: formData.phones,
                Email: formData.emails,
              }}
              social={[
                {
                  link: formData.facebook,
                  icon: <FacebookIcon />,
                  color: "facebook",
                },
                {
                  link: formData.x,
                  icon: <TwitterIcon />,
                  color: "twitter",
                },
                {
                  link: formData.whatsapp,
                  icon: <WhatsApp />,
                  color: "whatsapp",
                },
                {
                  link: formData.linkedin,
                  icon: <LinkedInIcon />,
                  color: "linkedin",
                },
                {
                  link: formData.instagram,
                  icon: <InstagramIcon />,
                  color: "instagram",
                },
                {
                  link: formData.youtube,
                  icon: <YouTubeIcon />,
                  color: "youtube",
                },
              ]}
              action={{ route: "", tooltip: "Editar contacto del evento" }}
              setOpenForm = {setOpenFormContact}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Card>
                <SoftBox pt={2} px={2}>
                  <SoftBox mb={0.5}>
                    <Constancy label="CONFIGURACIÓN CONSTANCIAS" setOpen={showFormOtherConstancy} data={constancies}/>
                  </SoftBox>
                </SoftBox>
              </Card>
          </Grid>
        </Grid>
      </SoftBox>
      { userData.type === 'admin' ?
      <SoftBox mt={5} mb={3}>
        <Grid container  >
          <Grid item xs={12} md={6} >
            <SoftBox p={1}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <SoftBox mb={0.5}>
                    <Sponsors data={sponsors} label="DATOS PATROCINADORES" setOpen={showFormOtherCatalogs} confirmDelete={confirmDelete}/>
                  </SoftBox>
                </SoftBox>
              </Card>
            </SoftBox>
            </Grid>
            <Grid item xs={12} md={6} >
              <SoftBox p={1}>
                <Card>
                  <SoftBox pt={2} px={2}>
                    <SoftBox mb={0.5}>
                      <Areas data={areas} label="EVENTOS INTERNOS" setOpen={showFormOtherCatalogs} confirmDelete={confirmDelete}/>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6} >
              <SoftBox p={1}>
                <Card>
                  <SoftBox pt={2} px={2}>
                    <SoftBox mb={0.5}>
                      <Coupons data={coupons} label="REGISTRO DE CUPONES" setOpen={showFormOtherCatalogs} confirmDelete={confirmDelete}/>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={6} >
              <SoftBox p={1}>
                <Card>
                  <SoftBox pt={2} px={2}>
                    <SoftBox mb={0.5}>
                      <Costs data={costs} label="REGISTRO DE COSTOS" setOpen={showFormOtherCatalogs} confirmDelete={confirmDelete}/>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </SoftBox>
            </Grid>
            </Grid>
      </SoftBox>
        : null
      }
      <SoftBox mt={5} mb={3}>
        <Card>
          <SoftBox pt={2} px={2}>
            <SoftBox mb={0.5}>
              <Participants 
                data={participants} 
                label="REGISTRO DE PARTICIPANTES" 
                setOpen={showFormOtherCatalogs} 
                formData={formData} 
                confirmRegister={confirmRegister}
                confirmGafete = {confirmGafete}
                setOpenDataParticipant = {setOpenDataParticipant}
                setDataParticipant = {setDataParticipant}
                confirmDelete={confirmDelete}
                downloadPDFGafete = {downloadPDFGafete}
                />
            </SoftBox>
          </SoftBox>
        </Card>
      </SoftBox>
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleForm} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
            {
              formSelected === 'sponsor' ?
                <SponsorForm setOpen = {setOpen} getCatalogs = {getCatalogs} event_id={id}/>
              : null
            }
            {
              formSelected === 'area' ?
                <AreaForm setOpen = {setOpen} getCatalogs = {getCatalogs} event_id={id}/>
              : null
            }
            {
              formSelected === 'coupon' ?
                <CouponForm setOpen = {setOpen} getCatalogs = {getCatalogs} event_id={id}/>
              : null
            }
            {
              formSelected === 'costs' ?
                <CostsForm setOpen = {setOpen} getCatalogs = {getCatalogs} event_id={id} perfiles={perfiles}/>
              : null
            }
            {
              formSelected === 'constancy' ?
                <ConstancyForm setOpen = {setOpen} getCatalogs = {getCatalogs} formDataConstancy={formDataConstancy} event_id={id}/>
              : null
            }
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openForm}
          onClose={()=>setOpenForm(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
              <DataForm
              formData = {formData} 
              setState = {setState}
              selectState = {selectState}
              errorFields = {errorFields} 
              statusForm = {statusForm}
              handleFilePayments={handleFilePayments}
              />
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendForm(false)}
                  >
                      Guardar
                </SoftButton>
              </Grid>
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpenForm(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openFormContact}
          onClose={()=>setOpenFormContact(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
              <DataFormContact
              formData = {formData} 
              setState = {setState}
              selectState = {selectState}
              errorFields = {errorFields} 
              statusForm = {statusForm}
              handleFilePayments={handleFilePayments}
              />
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendForm(false)}
                  >
                      Guardar
                </SoftButton>
              </Grid>
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpenFormContact(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openCover}
          onClose={()=>setOpenCover(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleDialog} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
            <SoftTypography variant="caption">{dataModalCoverLogo.label}*: </SoftTypography>
            <Input
              type="file"
              name = "img"
              inputProps={{
                accept: 'image/jpeg, image/png'
              }}
              onChange={event => handleFile(event)}
              className= {errorFields.includes("img") ? 'error-field' : null}
            />
            {
              uploading ?
                <SoftTypography variant="caption">Cargando...</SoftTypography>
              : null
            }
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openDataParticipant}
          onClose={()=>setOpenDataParticipant(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
            <DataFormParticipant 
                formData = {dataParticipant} 
                setState = {setStateParticipant}
                selectState = {selectState}
                errorFields = {errorFields} 
                statusForm = {statusForm}
            />
            <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendFormParticipant()}
                  >
                      Actualizar
                </SoftButton>
              </Grid>
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpenDataParticipant(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <ModalConfirm 
          open = {openConfirmDelete}
          setOpen = {setOpenConfirmDelete}
          action = {deleteItemSelected}
          message = {messageConfirm}
      />
      <ModalConfirm 
          open = {openConfirm}
          setOpen = {setOpenConfirm}
          action = {approveItemSelected}
          message = {messageConfirm}
      />
      <ModalConfirm 
          open = {openConfirmGafete}
          setOpen = {setOpenConfirmGafete}
          action = {approveGafeteItemSelected}
          message = {messageConfirm}
      />
      <Footer />
    </DashboardLayout>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '90%',
  maxWidth: '900px',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  maxWidth: '900px',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleDialog = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  height: '200px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default Overview;
