import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Data
import { Checkbox, Grid, Input } from "@mui/material";

import SoftAlert from "components/SoftAlert";

import { insertDataJson, getData, updateItem, deleteItem, validateForm, generateSlug} from 'tools/helpers';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import HeaderEvent from 'layouts/event/components/HeaderEvent';
import SoftButton from 'components/SoftButton';
import ModalAddField from './ModalAddField';
import ModalConfirm from 'components/ModalConfirm';


function Register() {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState('');
  const [dataDelete, setDataDelete] = useState({});
  const { slug, register_form_id } = useParams();
  const emptyData = {
    name : '',
    type : 'input',
  }
  const [formData, setFormData] = useState(emptyData);
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [errorFields, setErrorsFields] = useState([]);

  const [eventData, setEventData] = useState({});
  const [listForms, setListForms] = useState([]);
  const [formDataSelect, setFormDataSelect] = useState({
    catalog_id: 0,
    value: '',
  });
  const [listDataSelect, setListDataSelect] = useState([]);
  const [isRequerid, setIsRequerid] = useState(false);
  const [requireBill1, setRequireBill1] = useState(false);
  const [requireBill2, setRequireBill2] = useState(false);
  const [requireCoupon, setRequireCoupon] = useState(false);


  const inputsFormDefault = [
    {name: "Nombre", field:"name", type:"text", requerid: "true", default: true},
    {name: "Teléfono", field:"phone", type:"text", requerid: "true", default: true},
    {name: "Correo electrónico", field:"email", type:"email", requerid: "true", default: true},
  ]

  const setStateSelectForm = (e) => {
    setFormDataSelect({
      ...formDataSelect,
      [e.target.name]: e.target.value,
    });
  };

  const setState = (e) => {
    console.log(e.target.value)
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      key: generateSlug(e.target.value)
    });
  };

  const isRequiredField = () =>{
    setIsRequerid( isRequerid ? false: true );
    setFormData({
      ...formData,
      status: isRequerid ? 0 : 1
    })
  }


  const setShowBillInfo = async(isRequiredBill1, isRequiredBill2) =>{
    const route = `Event`;
    const newData = {
      bill_register_1: isRequiredBill1 ? 1 : 0,
      bill_register_2: isRequiredBill2 ? 1 : 0,

    }
    const send = await updateItem(`${route}/${eventData.id}`, newData);
  }

  const setShowBillCoupon = async(requireCoupon) =>{
    const route = `Event`;
    const newData = {
      required_coupon: requireCoupon ? 1 : 0,
    }
    const send = await updateItem(`${route}/${eventData.id}`, newData);
  }


  const showInfoBill1 = () =>{
    setRequireBill1(requireBill1 ? false: true );
    setShowBillInfo(requireBill1 ? false: true, requireBill2);
  }

  const showInfoBill2 = () =>{
    setRequireBill2(requireBill2 ? false: true );
    setShowBillInfo(requireBill1, requireBill2 ? false: true );
  }

  const showInfoCoupon = () =>{
    setRequireCoupon(requireCoupon ? false: true);
    setShowBillCoupon(requireCoupon ? false: true);
  }

  const getEvent = async () => {
    const route = `Event-Slug/${slug}`;
    const send = await getData(route);
    if (send.code === 200) {
      setEventData(send.data);
      setRequireBill1(parseInt(send.data.bill_register_1) === 1 ? true : false);
      setRequireBill2(parseInt(send.data.bill_register_2) === 1 ? true : false);
      setRequireCoupon(parseInt(send.data.required_coupon) === 1 ? true: false);
    }
  };

  const getCatalogs = async () => {
    const route = `Catalog-Form/${slug}/${register_form_id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setListForms(send.data);
    }
  };

  const getCatalogsDetails = async (id) => {
    const route = `Catalog-Detail/${id}`;
    const send = await getData(route);
    if (send.code === 200) {
      setListDataSelect(send.data);
    }
  };

  const requiredFields = ['name'];
  const sendForm = async() =>{
    setStatusForm({status: null, message: ""});
    const validationErrors = validateForm(formData, requiredFields);
    setErrorsFields(validationErrors);
    if(validationErrors.length > 0)
    {
      return;
    }

    const newData = {
      ...formData,
        slug: slug,
        register_form_id: register_form_id
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Catalog`;
    const send = await insertDataJson(route, newData);

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha agregado el campo satisfactoriamente",
        label: "success",
      });
      getCatalogs();
      if(send.data?.type === 'select'){
        setFormData(send.data);
        return;
      }
      setOpen(false);
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const sendFormSelect = async() =>{
    setStatusForm({status: null, message: ""});
    if(formDataSelect.value === '')
    {
      return;
    }

    const newData = {
      ...formDataSelect,
        catalog_id: formData.id
    }

    const route = `Catalog-Detail`;
    const send = await insertDataJson(route, newData);

    if (send.code === 201) {
      getCatalogsDetails(formData.id);
      getCatalogs();
      setFormDataSelect({
        ...formDataSelect,
        value: ''
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const sendFormUpdate = async ()=>{
    const route = 'Catalog';
    const send = await updateItem(`${route}/${formData.id}`, formData);
    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha editado el campo",
        label: "success",
      });
      setOpen(false);
      getCatalogs();
      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const selectedInputForm = (item)=>{
    setFormData(item);
    getCatalogsDetails(item.id);
    setIsRequerid(parseInt(item.status) === 1 ? true : false)
    setOpen(true);
  }

  const copyLink = () =>{
    const domain = window.location.hostname;
    navigator.clipboard.writeText(`${domain}/register/${slug}/${register_form_id}`);
    setStatusForm({
      status: true,
      message: "Enlace copiado en portapapeles...",
      label: "info",
    });
    setTimeout(()=>{
      setStatusForm({status: null, message: ""});
    }, 3000);
  }
  
  const deleteItemCatalog = async () =>{
    const send = await deleteItem(`Catalog/${dataDelete.id}`);
    if (send.code === 200) {
      setStatusForm({
        status: true,
        message: "Se ha eliminado el campo",
        label: "success",
      });
      setOpenConfirm(false);
      getCatalogs();
      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const deleteItemCatalogDetail = async (item) =>{
    const send = await deleteItem(`Catalog-Detail/${item.id}`);
    if (send.code === 200) {
      setStatusForm({
        status: true,
        message: "Se ha eliminado el campo",
        label: "success",
      });
      getCatalogsDetails(item.catalog_id);
      getCatalogs();
      return;
    }
    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const confirmDelete = (item) =>{
    setOpenConfirm(true);
    setMessageConfirm(`Está Seguro que desea eliminar [${item.name}]`)
    setDataDelete(item);
  }

  useEffect(()=>{
    getEvent();
    getCatalogs();
  },[])

  const ButtonEdit = ({item})=>(
    <Grid md={2} pl={1}>
      <SoftButton
        style={{ width: '100%', marginTop: 32 }}
        md={6}
        variant="gradient"
        color= "info"
        size="medium"
        onClick = {()=>selectedInputForm(item)}
      >
      <EditIcon fontSize="large" />
      </SoftButton>
    </Grid>
  )

  const ButtonDelete = ({item})=>(
    <Grid md={1} pl={1}>
      <SoftButton
        style={{ width: '100%', marginTop: 32 }}
        md={6}
        variant="gradient"
        color= "error"
        size="medium"
        onClick = {()=>confirmDelete(item)}
      >
        <DeleteIcon fontSize="large" />
      </SoftButton>
    </Grid>
  )

  const SelectForm = ({item})=>(
    <Grid container xs={12} md={6} px={1}>
      <Grid md={item.default ? 12 : 8} >
        <SoftTypography key={item.name} variant="caption">{item.name}{item.requerid || parseInt(item.status) === 1 ? '*' : ''}:</SoftTypography>
        <select
          className='select-custom'
          key={item.key}
          name={item.key}
          onChange={setState}
        >
          <option value="">Seleccione una opción</option>
          {
            item.catalogs_details.map(item=>(
              <option value={item.value} >{item.value}</option>
            ))
          }
        </select>
      </Grid>
      {
        ! item.default ?
        <>
          <ButtonEdit item = {item} />
          <ButtonDelete item = {item} />
        </>
        : null
      }
    </Grid>
  );

  const InputForm = ({item}) =>(
    <Grid container xs={12} md={6} px={1}>
      <Grid md={item.default ? 12 : 8}>
        <SoftTypography key={item.name} variant="caption">{item.name}{item.requerid || parseInt(item.status) === 1 ? '*' : ''}:</SoftTypography>
        <Input 
          key={item.field}
          name = {item.field}
          onChange={setState}
          className= {errorFields.includes(item.field) ? 'error-field' : null}
          padding = {10}
        />
      </Grid>
      {
        ! item.default ?
        <>
          <ButtonEdit item = {item} />
          <ButtonDelete item = {item} />
        </>
        : null
      }
  </Grid>
  );

  return (
    <PageLayout>
      <SoftBox p={5}>
        <HeaderEvent
        formData = {eventData}
        />
        <Grid  container justifyContent="flex-end" md={12} px={1} >
          <SoftButton
                md={6}
                variant="gradient"
                color= "info"
                size="medium"
                onClick={()=>copyLink()}
            >
                Copiar enlace
          </SoftButton>
        </Grid>
        <Card style={{ overflow: 'initial'}}>
          <SoftBox sx={8} justifyContent="center" alignContent="center" style={{display: 'flex'}}>
            <Grid p={4} container  style={{ maxWidth: 1100 }}>
            <Grid md={12}>
              {
                  statusForm.status ? (
                    <SoftBox extAlign="center" p={1}>
                      <SoftAlert color={statusForm.label}> 
                        <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                          {statusForm.message}
                        </SoftTypography>
                      </SoftAlert>
                    </SoftBox>
                  ): null
                }
              </Grid>
              <Grid md={12}>
                <SoftTypography variant="h2" fontWeight="bold" >{parseInt(register_form_id) === 1 ? 'Registro General': 'Registro Especial'}</SoftTypography>
              </Grid>
              <Grid pt={3} xs={12} container >
                {
                  inputsFormDefault.map( item =>(
                      <InputForm item={item} />
                  ))
                }
                {
                  listForms?.map( item =>(
                    item.type === "select" ?
                      <SelectForm item={item} />
                    :
                      <InputForm item={item} />
                  ))
                }
                {
                  parseInt(register_form_id) === 2 ?
                    <Grid md={6} px={1}>
                      <SoftTypography variant="caption"> Cupón:</SoftTypography>
                      <Input 
                        name = "coupon"
                        value = {formData.coupon}
                        onChange={setState}
                        className= {errorFields.includes("coupon") ? 'error-field' : null}
                        padding = {10}
                      />
                    </Grid>
                  : null
                }
                <Grid xs={12} md={6} px={1}>
                  <SoftButton
                      style={{ width: '100%', marginTop: 32 }}
                      md={6}
                      variant="gradient"
                      color= "secondary"
                      size="medium"
                      onClick = {()=> {
                        setStatusForm({status: null, message: ""});
                        setFormData(emptyData);
                        setOpen(true);
                      }}
                  >
                    <AddBoxIcon fontSize="large" />
                </SoftButton>
                </Grid>
                <Grid container md={12} p={1}>
                  <Grid md={6} mt={2} px={1}>
                    {
                      parseInt(register_form_id) === 1 ?
                        <Checkbox 
                            checked= {requireBill1}
                            color='secondary'
                            onChange = {showInfoBill1}
                        />
                        : 
                        <Checkbox 
                            checked= {requireBill2}
                            color='secondary'
                            onChange = {showInfoBill2}
                        />
                    }                    
                    <SoftTypography variant="caption">¿Agregar datos de facturación?</SoftTypography>
                  </Grid>
                  <Grid md={6} mt={2} px={1}>
                    {
                      parseInt(register_form_id) === 1 ?
                          null
                        : 
                        <>
                          <Checkbox 
                              checked= {requireCoupon}
                              color='secondary'
                              onChange = {showInfoCoupon}
                          />
                        <SoftTypography variant="caption">¿Cupón obligatorio?</SoftTypography>
                      </>
                    }                    
                  </Grid>
                </Grid>
                {
                  (requireBill1 && parseInt(register_form_id) === 1) || (requireBill2 && parseInt(register_form_id) === 2) ? 
                    <Grid container md={12} px={1}>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> Razón social*:</SoftTypography>
                        <Input 
                          name = "bussiness_name"
                          value = {formData.bussiness_name}
                          onChange={setState}
                          className= {errorFields.includes("bussiness_name") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> RFC*:</SoftTypography>
                        <Input 
                          name = "rfc"
                          value = {formData.rfc}
                          onChange={setState}
                          className= {errorFields.includes("rfc") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={6} px={1}>
                        <SoftTypography variant="caption"> Dirección*:</SoftTypography>
                        <Input 
                          name = "address"
                          value = {formData.address}
                          onChange={setState}
                          className= {errorFields.includes("address") ? 'error-field' : null}
                          padding = {10}
                        />
                      </Grid>
                      <Grid md={3} px={1}>
                        <SoftTypography variant="caption"> Uso de CFDI*:</SoftTypography>
                        <select
                          className='select-custom'
                          name = "cfdi"
                          onChange={setState}
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="Gastos en general">Gastos en general</option>
                        </select>
                      </Grid>
                      <Grid md={3} px={1}>
                        <SoftTypography variant="caption"> Regímen*:</SoftTypography>
                        <select
                          className='select-custom'
                          name = "regime"
                          onChange={setState}
                        >
                          <option value="">Seleccione una opción</option>
                          <option value="General de Ley Personas Morales">General de Ley Personas Morales</option>
                          <option value="Personas Morales con Fines no Lucrativos">Personas Morales con Fines no Lucrativos</option>
                          <option value="Sueldos y Salarios e Ingresos Asimilados a Salarios">Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                          <option value="Personas Físicas con Actividades Empresariales y Profesionales">Personas Físicas con Actividades Empresariales y Profesionales</option>
                          <option value="Sin obligaciones fiscales">Sin obligaciones fiscales</option>
                          <option value="Arrendamiento">Arrendamiento</option>
                          <option value="Régimen de Enajenación o Adquisición de Bienes">Régimen de Enajenación o Adquisición de Bienes</option>
                          <option value="Residentes en el Extranjero sin Establecimiento Permanente en México">Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                          <option value="Ingresos por Dividendos (socios y accionistas)">Ingresos por Dividendos (socios y accionistas)</option>
                          <option value="Ingresos por intereses">Ingresos por intereses</option>
                          <option value="Régimen de los ingresos por obtención de premios">Régimen de los ingresos por obtención de premios</option>
                          <option value="Sociedades Cooperativas de Producción que optan por diferir sus ingresos">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                          <option value="Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                          <option value="Opcional para Grupos de Sociedades">Opcional para Grupos de Sociedades</option>
                          <option value="Coordinados">Coordinados</option>
                          <option value="Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                          <option value="Régimen Simplificado de Confianza">Régimen Simplificado de Confianza</option>
                        </select>
                      </Grid>
                    </Grid>
                  : null
                }
              </Grid>
            <Grid xs={12} md={6} px={1} mt={5}>
              <SoftButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                >
                    Enviar
              </SoftButton>
            </Grid>
          </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <ModalAddField 
        open = {open}
        setOpen = {setOpen}
        formData = {formData}
        formDataSelect = {formDataSelect}
        setState = {setState}
        setStateSelectForm = {setStateSelectForm}
        statusForm = {statusForm}
        listDataSelect = {listDataSelect}
        errorFields = {errorFields}
        sendForm = {sendForm}
        sendFormSelect = {sendFormSelect}
        sendFormUpdate = {sendFormUpdate}
        deleteItemCatalogDetail = {deleteItemCatalogDetail}
        isRequiredField = {isRequiredField}
        isRequerid = {isRequerid}
        setIsRequerid = {setIsRequerid}
      />
      <ModalConfirm 
        open = {openConfirm}
        setOpen = {setOpenConfirm}
        action = {deleteItemCatalog}
        message = {messageConfirm}
      />
    </PageLayout>
  );
}

export default Register;
